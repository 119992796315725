import React,{Component} from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import {setLanguageToI18n} from '../../services/i18n';
import StoreItem from './rigth-column/';
import PaymentInfo from './left-column/';
import {storeSteps} from '../../services/util';


import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";

import "./styles.scss";

export default class SelectPayment extends Component {
    state = {
        loading: true,
        packs: 1
    }

    constructor (props) {
        super(props);
        let getParams = localStorage.getItem('startParams');
        if(getParams)
            if(JSON.parse(getParams).lang)
                setLanguageToI18n(JSON.parse(getParams).lang);
            else
                setLanguageToI18n("en-US");
        else
            setLanguageToI18n("en-US");
    }

    componentDidMount() {
        const { id,server } = this.props.match.params;
        window.dispatchEvent(new CustomEvent('add-route',{detail:{label:"Pagamento",url:`/${server}/${id}`,level:3}}));
        let storeData = JSON.parse(localStorage.getItem('storeData'));
        if(storeData)
        {
            const storeItem = storeData.itemTable.filter(value => {return value.sku===id;})[0];
            this.setState({loading: false, storeItem});
        }
    }

    changePackNumber = (packs) => {
        this.setState({packs});
    }

    render() {
        const { server, id } = this.props.match.params;
        const {loading,storeItem,packs} = this.state;

        if(loading)
        {
            return (
                <div className="spinner-div">
                    <ProgressSpinner/>
                </div>
            );
        }
        else
        {
            return (
                <div>
                    <section className="p-grid p-align-center payment-section">
                        <div className="p-col left-column">
                            <PaymentInfo item={storeItem} server={server} packs={packs} packChangeFunction={this.changePackNumber} id={id}/>
                        </div>
                        <div className="p-col right-column">
                            <StoreItem item={storeItem} server={server} packs={packs}/>
                        </div>
                    </section>
                    {storeSteps(2)}
                </div>
            );
        }
    }
}