import React from 'react';
import {Steps} from 'primereact/steps';
import {translate} from './i18n';

const idToServerName = (id) => {
        let serverNames = {
            origem: "Origem",
            odin: "Asgard",
            globalusd: "Global (USD)",
            globalbrl: "Global (BRL)",
            seasonusd: "Loki (USD)",
            seasonbrl: "Loki (BRL)"
        };
        return serverNames[id]?serverNames[id]:"WYD";
    }
const storeSteps = (index) => {
        return (
            <Steps activeIndex={index} model={[{label: translate('component.steps.step1')},{label: translate('component.steps.step2')},{label: translate('component.steps.step3')},{label: translate('component.steps.step4')}]} />
        );
    }

export {idToServerName,storeSteps};