import React from 'react';

import Routes from './routes';

import "./styles.scss";


const App = () => (
    <div className="App">
      <Routes/>
    </div>
  );

export default App;
