import React, {Component} from 'react';
import {root} from '../../../services/api';
import {translate} from '../../../services/i18n';
import {ProgressSpinner} from 'primereact/progressspinner';
import {InputText} from 'primereact/inputtext';
import {SelectButton} from 'primereact/selectbutton';
import {Link} from 'react-router-dom';
import "./styles.scss";

export default class PaymentInfo extends Component {
    state = {custom: false};

    loadStoreData = async () => {
        const {server,packs} = this.props;
        let response = await root.get('/../../data/config.json');
        let storeInfo = response.data[server];
        this.setState({storeInfo,packs});
    };

    printGatewayButton = (gateway,packs) => {
        const { id,server } = this.props;
        return (
            <Link to={`/${server}/${id}/${packs}/${gateway}`} key={gateway} className="gateway-button">
                <div  className="p-button-raised p-button-secondary p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3" label={" "}>
                    <img src={"/img/gateway/"+gateway+".png"} alt={gateway}/>
                </div>
            </Link>
        );
    }

    changePackNumber = (value) => {
        const {custom} = this.state;
        value = Math.max(value,1);
        this.props.packChangeFunction(value);
        if(!custom && value > 5)
        {
            this.setState({packs: value,custom: true});
        }
        else {
            this.setState({packs: value});
        }
    }

    render () {
        const {storeInfo,packs,custom} = this.state;
        const packOptions = [{label: "1", value: 1},{label: "2", value: 2},{label: "3", value: 3},{label: "4", value: 4},{label: "5", value: 5},{label: translate('page.payment.other'), value: 6}];

        if(storeInfo) {
            return (
                <div>
                    <p className="p-grid p-justify-center p-col">{translate('page.payment.packsQuestion')}</p>
                    <div className="p-grid p-justify-center p-col">
                        {!custom && <SelectButton value={packs} options={packOptions} onChange={(e) => this.changePackNumber(e.value)} />}
                        {custom && <InputText type="text" keyfilter="pint" value={packs} onChange={(e) => this.changePackNumber(e.target.value)} />}
                    </div>
                    <p className="p-grid p-justify-center p-col">{translate('page.payment.methodQuestion')}</p>
                    <div className="p-grid p-justify-center p-col">
                        {storeInfo.paymentMethods.map(value => this.printGatewayButton(value,packs))}
                    </div>
                    <div className="description p-grid p-justify-center p-col">{translate('page.payment.note')}</div>
                </div>
            );
        }
        else {
            this.loadStoreData();
            return (
                <div className="spinner-div">
                    <ProgressSpinner/>
                </div>
            );
        }
    }
}