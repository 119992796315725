import React,{Component} from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import {InputMask} from "primereact/inputmask";
import {InputText} from 'primereact/inputtext';
import {api,viacep,root} from '../../services/api';
import {setLanguageToI18n,translate} from '../../services/i18n';
import {Button} from 'primereact/button';
import {storeSteps} from '../../services/util';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import "./styles.scss";

export default class LastStep extends Component {

    state = { data: {} };

    constructor (props) {
        super(props);
        let getParams = localStorage.getItem('startParams');
        if(getParams)
            if(JSON.parse(getParams).lang)
                setLanguageToI18n(JSON.parse(getParams).lang);
            else
                setLanguageToI18n("en-US");
        else
            setLanguageToI18n("en-US");
    }

    validPhone = false;

    componentDidMount() {
        const { id,server,amount,gateway } = this.props.match.params;
        window.dispatchEvent(new CustomEvent('add-route',{detail:{label:"Confirmation",url:`/${server}/${id}/${amount}/${gateway}`,level:4}}));
        this.startTransaction();
    }

    personalInformationCallback = (informationComplete,ignoreFields) => {

        const {data,status} = informationComplete;

        let filtered = [];
        if(data.missing)
            filtered = data.missing.filter((value) => {return !ignoreFields.includes(value)});

        if(status === 200)
        {
            if(data.isComplete || (filtered && filtered.length===0))
            {
                this.setState({missing: undefined});
            }
            else
            {
                if(data.message === "invalid token")
                {
                    console.log(2);
                }
                else
                {
                    this.setState({missing: filtered});
                }
            }
        }
    }

    startTransaction = async () => {

        const { server } = this.props.match.params;

        let response = await root.get('../../../../data/config.json');
        let storeInfo = response.data[server];

        const getParams = JSON.parse(localStorage.getItem('startParams'));
        let {username,token} = getParams;

        const params = new URLSearchParams();
        params.append('operation', 'isInformationComplete');
        params.append('username', username);
        params.append('token', token);

        let informationComplete = await api.post("personal-info.php",params);
        const isInIFrame = window.location !== window.parent.location;

        this.personalInformationCallback(informationComplete,storeInfo.ignoreFields);

        if(!this.state.missing)
        {
            const { id,server,amount,gateway } = this.props.match.params;
            const params2 = new URLSearchParams();
            params2.append('gateway',gateway);
            params2.append('sku',id);
            params2.append('server',server);
            params2.append('currencyCode',(server==='globalusd'||server==='seasonusd')?'USD':'BRL');
            params2.append('amount',amount);
            params2.append('username', username);
            params2.append('token', token);

            let response = await api.post("payment-v2.php",params2);

            if(gateway==="iugu")
            {
                window.open(response.data.secure_url, isInIFrame ? '_self' : '_parent');
            }
            else if(gateway==="picpay")
            {
                window.open(response.data.redirect.paymentUrl, isInIFrame ? '_self' : '_parent');
            }
            else if(gateway==="boacompra")
            {
                document.getElementById("store_id").setAttribute("value", response.data.store_id);
                document.getElementById("return").setAttribute("value", response.data.return);
                document.getElementById("notify_url").setAttribute("value", response.data.notify_url);
                document.getElementById("currency_code").setAttribute("value", response.data.currency_code);
                document.getElementById("order_id").setAttribute("value", response.data.order_id);
                document.getElementById("order_description").setAttribute("value", response.data.order_description);
                document.getElementById("client_email").setAttribute("value", response.data.client_email);
                document.getElementById("amount").setAttribute("value", response.data.amount);
                document.getElementById("hash_key").setAttribute("value", response.data.hash_key);
                document.getElementById("country_payment").setAttribute("value", response.data.currency_code === "BRL" ? "BR" : "US");

                document.getElementById('form').submit();
            }
            else if(gateway==="paymentwall")
            {
                window.open(response.data.secure_url, isInIFrame ? '_self' : '_parent');
            }
        }
    }

    isCPFValid = (value) => {
        var strCPF = value.match(/\d+/g).join('');
        var Soma;
        var Resto;

        Soma = 0;
        if (strCPF === "00000000000")
            return false;

        for (var i=1; i<=9; i++) 
            Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11))
            Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10)) )
            return false;

        Soma = 0;
        for (i = 1; i <= 10; i++)
            Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11))
            Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11) ) )
            return false;
        return true;
    }

    isCEPValid = async (value) => {
        if(value)
        {
            var strCEP = value.match(/\d+/g).join('');
            let cepInfo = await viacep.get(`${strCEP}/json/`);
            this.setState({validCEP: value.length>0 && value.indexOf("_")===-1 && cepInfo.data.cep?true:false});
        }
    }

    validInput = () => {
        const validFunction = {
            cep: (value) => this.state.validCEP,
            cpf: (value) => {return value?(value.length>0 && value.indexOf("_")===-1 && this.isCPFValid(value)):false},
            fullname: (value) => {return value?(value.length>0 && value.indexOf("_")===-1):false},
            phone: (value) => this.validPhone
        }
        const {data,missing} = this.state;
        var invalids = missing.filter(miss => {
            return !(validFunction[miss](data[miss]));
        });
        return invalids.length===0;
    }

    submitMissingFields = async () => {
        const {data,missing} = this.state;

        const cleanFunction = {
            cep: value => value.match(/\d+/g).join(''),
            cpf: value => value.match(/\d+/g).join(''),
            fullname: value => value,
            phone: value => value.match(/\d+/g).join('')
        }

        const getParams = JSON.parse(localStorage.getItem('startParams'));
        let {username,token} = getParams;

        const params = new URLSearchParams();
        params.append('operation', 'updateInformation');
        params.append('username', username);
        params.append('token', token);
        missing.forEach(miss => {
            params.append(miss, cleanFunction[miss](data[miss]));
        });
        await api.post("personal-info.php",params);

        this.startTransaction();
    }

    render() {
        const modules = {
            phone: () => {
                return (
                    <PhoneInput key="phone"
                        isValid={
                            (inputNumber, onlyCountries) => {
                                return onlyCountries.some((country) => {
                                    this.validPhone = inputNumber.startsWith(country.dialCode) && (!country.format || ((country.format+"").split(".").length-1 === inputNumber.length));
                                    return this.validPhone;
                                });
                            }
                        }
                        country={'br'}
                        preferredCountries={['br','us']}
                        placeholder={translate('page.confirmation.phone')}
                        value={this.state.data.phone}
                        onChange={(phone) => this.setState({data: {...this.state.data,phone}})}/>
              );
            },
            fullname: () => {
                return (
                    <InputText key="Fullname"
                        placeholder={translate('page.confirmation.fullname')}
                        value={this.state.data.fullname}
                        onChange={(fullname) => this.setState({data: {...this.state.data,fullname: fullname.target.value}})}/>
                );
            },
            cep: () => {
                return (
                    <InputMask key="CEP"
                        placeholder={translate('page.confirmation.cep')} 
                        value={this.state.data.cep}
                        onChange={(cep) => 
                            {
                                this.isCEPValid(cep.target.value);
                                this.setState({data: {...this.state.data,cep: cep.target.value}});
                            }
                        }
                        mask="99999-999"/>
                );
            },
            cpf: () => {
                return (
                    <InputMask key="CPF"
                        placeholder={translate('page.confirmation.cpf')}  
                        value={this.state.data.cpf} 
                        mask="999.999.999-99"
                        onChange={(cpf) => this.setState({data: {cpf: cpf.target.value}})}/>
                );
            }
        }

        const {missing} = this.state;


        if(missing)
        {
            console.log(missing);
            return (
                <div className="p-justify-center p-md-12">
                    <div className="p-grid p-fluid p-md-4">
                        {
                            missing.map(item => 
                            {
                                return (
                                    <div key={item} className="p-md-12">
                                        {modules[item]()}
                                    </div>
                                );
                            })
                        }
                        <div className="p-md-12">
                            <Button label={translate('page.confirmation.submit')}  disabled={!this.validInput()} onClick={this.submitMissingFields}/>
                        </div>
                    </div>
                    {storeSteps(3)}
                </div>
            );
        }
        else
        {
            return (
                <div className="spinner-div">
                    <ProgressSpinner/>
                    <form action="https://billing.boacompra.com/payment.php" id="form" method="POST" name="billing" target="_parent" className="ng-untouched ng-pristine ng-valid">
                        <input id="store_id" name="store_id" type="hidden" value=""/>
                        <input id="return" name="return" type="hidden" value=""/>
                        <input id="notify_url" name="notify_url" type="hidden" value=""/>
                        <input id="currency_code" name="currency_code" type="hidden" value=""/>
                        <input id="order_id" name="order_id" type="hidden" value=""/>
                        <input id="order_description" name="order_description" type="hidden" value=""/>
                        <input id="amount" name="amount" type="hidden" value=""/>
                        <input id="client_email" name="client_email" type="hidden" value=""/>
                        <input id="hash_key" name="hash_key" type="hidden" value=""/>
                        <input id="country_payment" name="country_payment" type="hidden" value=""/>
                    </form>
                    {storeSteps(3)}
                </div>
            );
        }
    }
}