import I18n from 'i18n-js'

import {en} from './locales/en-US'
import {pt} from './locales/pt-BR'

const normalizeTranslate = {
  'en_US': 'en_US',
  'pt_BR': 'pt_BR',
  'en': 'en_US',
  'pt_US': 'pt_BR',
  'en-GB': 'en_US',
  'en-US': 'en_US',
  'pt-BR': 'pt_BR',
}

I18n.translations = {
  'en_US': en,
  'pt_BR': pt,
}

export const setLanguageToI18n = (language) => {
    const translateNormalize = normalizeTranslate[language]
    const iHaveThisLanguage = I18n.translations.hasOwnProperty(translateNormalize)
    iHaveThisLanguage? I18n.locale = translateNormalize : I18n.defaultLocale = 'en_US'
}

export const translate = (key) => I18n.t(key)