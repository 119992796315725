import React, {Component} from 'react';
import {root} from '../../services/api';
import {setLanguageToI18n,translate} from '../../services/i18n';
import {Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import ReactGA from 'react-ga';

import {storeSteps} from '../../services/util';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";

import "./styles.scss";

export default class SelectStore extends Component {
    state = {
        stores: [],
        storeInfo: {},
        loading: true
    }

    constructor (props) {
        super(props);
        let getParams = localStorage.getItem('startParams');
        if(getParams)
            if(JSON.parse(getParams).lang)
                setLanguageToI18n(JSON.parse(getParams).lang);
            else
                setLanguageToI18n("en-US");
        else
            setLanguageToI18n("en-US");
    }

    componentDidMount() {
        this.loadProducts();
    }

    loadProducts = async () => {
        const queryString = require('query-string');
        const getParams = queryString.parse(this.props.location.search);
        if(getParams.username)
        {
            localStorage.setItem('startParams', JSON.stringify(getParams));
            setLanguageToI18n(getParams.lang);
            ReactGA.initialize('UA-121599966');
            ReactGA.set({
                userId: getParams.username
            });
        }
        const response = await root.get('/data/config.json');
        const {stores, ...storeInfo} = response.data;
        window.dispatchEvent(new CustomEvent('add-route',{detail:{label:"home",url:`/`,level:1}}));
        this.setState({stores,storeInfo,loading: false});

    }

    printStore = (store,storeInfo) => {
        return (
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3" key={store}>
                <Link to={`/${store}`}>
                    <div className="store-item">
                        <img src={storeInfo[store].img} alt={store}></img>
                    </div>
                </Link>
            </div>
        );
    }

    render() {
        const {stores,loading,storeInfo} = this.state;
        if(loading)
        {
            return (
                <div className="spinner-div">
                    <ProgressSpinner/>
                </div>
            );
        }
        else
        {
            return (
                <div className="store-list">
                    <h1>{translate("page.select.title")}</h1>
                    <div className="p-grid p-align-center p-justify-center stores">
                        {stores.map(store => this.printStore(store,storeInfo))}
                    </div>
                    {storeSteps(0)}
                </div>
            );
        }
    }
}