import React, {Component} from 'react';
import {api,root} from '../../services/api';
import {idToServerName,storeSteps} from '../../services/util';
import {translate,setLanguageToI18n} from '../../services/i18n';
import {Accordion,AccordionTab} from 'primereact/accordion';
import {Dialog} from 'primereact/dialog';
import {Steps} from 'primereact/steps';
import {ScrollPanel} from 'primereact/scrollpanel';

import {ProgressSpinner} from 'primereact/progressspinner';

import StoreItem from './store-item/index';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css"

import "./styles.scss";
import { Button } from 'primereact/button';

export default class SelectStore extends Component {

    state = {
        loading: true,
        historic: false
    }

    constructor (props) {
        super(props);
        let getParams = localStorage.getItem('startParams');
        if(getParams)
            if(JSON.parse(getParams).lang)
                setLanguageToI18n(JSON.parse(getParams).lang);
            else
                setLanguageToI18n("en-US");
        else
            setLanguageToI18n("en-US");
    }

    componentDidMount() {
        this.loadProducts();
        const { server } = this.props.match.params;
        window.dispatchEvent(new CustomEvent('add-route',{detail:{label:idToServerName(server),url:`/${server}`,level:2}}));
    }

    loadProducts = async () => {
        const getParams = JSON.parse(localStorage.getItem('startParams'));

        if(getParams)
        {
            let user = getParams.username;
            const { server } = this.props.match.params;
    
            let response = await root.get('/../../data/config.json');
            let storeInfo = response.data[server];
    
            let response2 = await api.get(`store.php?AccountName=${user}`);
            let storeData = response2.data.filter(info => info.serverName.toLowerCase()===server.toLowerCase())[0];
            localStorage.setItem('storeData', JSON.stringify(storeData));
            this.setState({store:server,storeInfo,storeData,loading: false});
        }
    }

    printStoreItem = (item) => {
        const { server } = this.props.match.params;
        return (
            <div className={item.status==="normal"?"p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3":"p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6"}  key={item.sku}>
                <StoreItem item={item} server={server}></StoreItem>
            </div>
        );
    }

    printHistoric = (storeData) => {
        return (
            <Dialog header="Historic" visible={this.state.historic} style={{width: '50vw'}} modal={true} onHide={() => this.setState({historic: false})}>
                <ScrollPanel style={{width: '100%', height: '500px'}}>
                    <Accordion>
                        {storeData.historic.sort((a,b) => {return new Date(b.date)- new Date(a.date)}).slice(0,5).map(value => {
                            let step = 1;
                            if(value.status!== 'waiting')
                                step = 3;
                            return (
                                <AccordionTab header={`${value.name} - ${value.date}`}>
                                    <br/>
                                    <b>Name: </b> {value.name} <br/>
                                    <b>SKU: </b> {value.sku} <br/>
                                    <b>Gateway: </b> {value.gateway} <br/>
                                    <b>Last Update: </b> {value.date} <br/>
                                    <br/>
                                    <Steps model={[{label: 'Started'},{label: 'Waiting'},{label: 'Processing'}, {label: 'Delivered'}]} activeIndex={step} readOnly={true}></Steps>
                                </AccordionTab>
                            );
                        })}
                    </Accordion>
                </ScrollPanel>
            </Dialog>
        );
    }

    render() {
        const {loading,storeInfo,storeData} = this.state;
        if(loading)
        {
            return (
                <div className="spinner-div">
                    <ProgressSpinner/>
                </div>
            );
        }
        else
        {
            return (
                <div className="store-list">
                    {this.printHistoric(storeData)}
                    <Button className='historicButton' label='Ver histórico' onClick={() => this.setState({historic: true})}></Button>
                    <div className="logo-div">
                        <img src={"../../"+storeInfo.img} alt={storeData.name}></img>
                    </div>
                    <div className="p-grid p-align-center store-items">
                        <div className="p-col-12">
                            <div className="store-warning">{translate('page.store.tip.part1')}<a href="https://www.facebook.com/GlobalWYD/" target="_parent">{translate('page.store.tip.partSocial')}</a>{translate('page.store.tip.part2')}<a href="https://feedback.kersef.com/" target="_parent">{translate('page.store.tip.partSystem')}</a></div>
                        </div>
                        {storeData.itemTable.map(item => this.printStoreItem(item))}
                    </div>
                    {storeSteps(1)}
                </div>
            );
        }
    }
}