import React, {Component} from 'react';
import "./styles.scss";

export default class StoreItem extends Component {
    printDescription = (gift,server,packs) => {
        return (
            <div key={gift.name} className="detail-gift">
                <img src={`https://wydmisc.raidhut.com.br/icons/${(server==="odin" || server === "origem")?server:"global"}/${gift.icon}`} alt={gift.name}/> {packs>1?packs+"x ":""}{gift.name}
            </div>
        );
    }

    printNPTotal = (item,NPResult) =>
    {
        if(item.NP+item.NPBonus>0)
            return <h1>{NPResult}</h1>;
        else
            return null;
    }

    render () {
        const {item, server,packs} = this.props;
        const NPOps = `${(parseInt(item.NP)*packs).toLocaleString(item.currencycode==="BRL"?"pt-BR":"en-US")} NP + ${(parseInt(item.NPBonus)*packs).toLocaleString(item.currencycode==="BRL"?"pt-BR":"en-US")} NP =`;
        const NPResult = (item.NPBonus===0)?`${(parseInt(item.NP)*packs).toLocaleString(item.currencycode==="BRL"?"pt-BR":"en-US")} NP`:`${(parseInt(item.NP)*packs + parseInt(item.NPBonus)*packs).toLocaleString(item.currencycode==="BRL"?"pt-BR":"en-US")} NP`;
        const priceText = (item.currencycode==="BRL"?"R$ ":"U$ ")+(item.priceReal*(1-item.discount)*packs).toLocaleString(item.currencycode==="BRL"?"pt-BR":"en-US", {minimumFractionDigits: 2, maximumFractionDigits:2});
        return (
            <div className="pack-info">
                <img src={"../../img/pack/"+item.icon} alt={item.name}/>
                {item.NPBonus>0 && <p>{NPOps}</p>}
                {this.printNPTotal(item,NPResult)}
                <h2>{priceText}</h2>
                <hr />
                <div className="gifts-field">
                    {item.gifts.filter(value => {return value.name !== "Nordic Points EXTRA!";}).map(gift => this.printDescription(gift,server,packs))}
                </div>
            </div>
        );
    }
}