import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

import SelectStore from './pages/select';
import Store from './pages/store';
import SelectPayment from './pages/payment';
import LastStep from './pages/confirmation';
import Header from './components/Header';

const Routes = () => (
    <BrowserRouter>
        <Header/>
        <Switch>
            <Route exact path="/" component={SelectStore}/>
            <Route exact path="/:server" component={Store}/>
            <Route exact path="/:server/:id" component={SelectPayment}/>
            <Route exact path="/:server/:id/:amount/:gateway" component={LastStep}/>
        </Switch>
    </BrowserRouter>
);

export default Routes;